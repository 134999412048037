<template>
    <div class="admin_page_body" v-loading="isRequestLoading">
        <el-form ref="form" :inline="true" :model="form" size="small" :rules="rules" label-position="top">
            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">基本信息</h1>
                <div class="data_detail mt24">
                    <el-form-item label="标题" prop="title" class="full">
                        <el-input v-model="form.title" placeholder="请输入标题" style="width: 357px"></el-input>
                    </el-form-item>

                    <el-form-item label="商品名称" prop="goodName">
                        <el-input v-model="form.goodName" placeholder="请输入商品名称"></el-input>
                    </el-form-item>

                    <el-form-item label="商品数量" prop="num" style="width: 20%; padding-right: 12px">
                        <el-input v-model.number="form.num" placeholder="请输入商品数量"></el-input>
                    </el-form-item>

                    <el-form-item label="单位" prop="unitId" style="width: 13.33%">
                        <el-select v-model.number="form.unitId" placeholder="请选择">
                            <el-option v-for="(item, index) in unitList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="商品规格" prop="sku">
                        <el-input v-model="form.sku" placeholder="请输入商品规格说明"></el-input>
                    </el-form-item>

                    <el-form-item label="商品图片" class="full" prop="imageUrl">
                        <div class="flexColumn">
                            <div class="uploadExample">
                                <el-upload
                                        class="avatar-uploader"
                                        :action="uploadURL"
                                        :before-upload="beforeUploadImage"
                                        :on-success="imageUrlUpload"
                                        :show-file-list="false">
                                    <img v-if="form.imageUrl" :src="imageURL + form.imageUrl" class="avatar">
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </div>
                            <div class="el-upload__tip">支持扩展名：.jpg、.jpeg、.png格式，图片大小不超过10M</div>
                        </div>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">供应说明</h1>
                <div class="data_detail mt24">
                    <el-form-item label="预出售日期" prop="receiptAt" class="full">
                        <el-date-picker v-model="form.receiptAt" type="date" placeholder="请选择预出售日期" value-format="yyyy-MM-dd" :picker-options="pickerOptionsReceiptAt" style="width: 357px"></el-date-picker>
                    </el-form-item>

                    <el-form-item label="供应截止日期" prop="stopAt" class="full">
                        <el-date-picker v-model="form.stopAt" type="date" placeholder="请选择供应截止日期" value-format="yyyy-MM-dd" :picker-options="pickerOptionsStopAt" style="width: 357px"></el-date-picker>
                    </el-form-item>

                    <el-form-item label="价格说明" prop="price" class="full">
                        <div class="line">
                            <div class="item" style="width: 357px">
                                <el-input v-model="form.price" placeholder="请输入供应商品单价"></el-input>
                                <span class="text">元</span>
                            </div>

                            <el-radio-group v-model="form.priceStatus" class="ml48">
                                <el-radio :label="1">可议价</el-radio>
                                <el-radio :label="2">不可议价</el-radio>
                                <el-radio :label="3">面议</el-radio>
                            </el-radio-group>
                        </div>
                    </el-form-item>

                    <el-form-item label="详细说明" class="full" prop="content">
                        <el-input v-model="form.content" type="textarea" :rows="4" placeholder="请输入详细说明信息"></el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="admin_page_item mt18 pr0 pb0">
                <h1 class="title">联系方式</h1>
                <div class="data_detail mt24">
                    <el-form-item label="联系人" prop="contactName">
                        <el-input v-model="form.contactName" placeholder="请输入联系人"></el-input>
                    </el-form-item>

                    <el-form-item label="联系电话" prop="contactPhone">
                        <el-input v-model="form.contactPhone" placeholder="请输入联系电话"></el-input>
                    </el-form-item>
                </div>
            </div>

            <div class="page_footer mt24">
                <el-button type="primary" size="small" @click="submitForm('form')" :loading="isSubmitLoading">确认，发布供应单</el-button>
                <el-button size="small" @click="$router.go(-1)">返回</el-button>
            </div>
        </el-form>
    </div>
</template>

<script>
  import {imageURL, uploadURL} from "../../../../utils/config";
  import {beforeUploadImage} from "../../../../utils/method";
  import {getUnit, postSupply} from "../../../../api/admin/supplyBuying";

  export default {
    name: "supplyBuyingBuyingAdd",
    data () {
      return {
        isRequestLoading: false,
        form: {
          title: '',
          goodName: '',
          num: '',
          unitId: null,
          sku: '',
          imageUrl: '',
          stopAt: '',
          receiptAt: '',
          price: '',
          priceStatus: 1,
          content: '',
          contactName: '',
          contactPhone: '',
        },
        rules: {
          title: [
            { required: true, message: '请输入标题', trigger: 'blur' }
          ],
          goodName: [
            { required: true, message: '请输入商品名称', trigger: 'blur' }
          ],
          num: [
            { required: true, message: '请输入商品数量', trigger: 'blur' }
          ],
          unitId: [
            { required: true, message: '请选择单位', trigger: 'change' }
          ],
          stopAt: [
            { required: true, message: '请选择供应截止日期', trigger: 'change' }
          ],
          receiptAt: [
            { required: true, message: '请选择预出售日期', trigger: 'change' }
          ],
          price: [
            { required: true, message: '请输入供应商品单价', trigger: 'blur' }
          ],
          content: [
            { required: true, message: '请输入详细说明信息', trigger: 'blur' }
          ],
          contactName: [
            { required: true, message: '请输入联系人', trigger: 'blur' }
          ],
          contactPhone: [
            { required: true, message: '请输入联系电话', trigger: 'blur' },
            { pattern: '^([1]\\d{10}|([\\(（]?0[0-9]{2,3}[）\\)]?[-]?)?([2-9][0-9]{6,7})+(\\-[0-9]{1,4})?)$', message: '请输入正确的联系电话', trigger: 'blur'}
          ],
        },
        imageURL: imageURL,
        uploadURL: uploadURL,
        beforeUploadImage: beforeUploadImage,
        unitList: [],
        isSubmitLoading: false,
      }
    },
    created() {
      this.$store.commit('changeScrollTop');
      this.getUnit()
    },
    computed: {
      pickerOptionsStopAt: function () {
        let that = this;
        return {
          disabledDate(time) {
            if (that.form.receiptAt) {
              return time.getTime() < new Date(that.form.receiptAt)
            }
            return time.getTime() < Date.now()
          }
        }
      },
      pickerOptionsReceiptAt: function () {
        let that = this;
        return {
          disabledDate(time) {
            if (that.form.stopAt) {
              return time.getTime() < Date.now() || time.getTime() > new Date(that.form.stopAt)
            }
            return time.getTime() < Date.now()
          }
        }
      },
    },
    methods: {
      getUnit () {
        getUnit().then(res => {
          if (res.code === 0) {
            this.unitList = res.data
          }
        })
      },
      imageUrlUpload (res) {
        if (res.code === 0) {
          this.form.imageUrl = res.data
        }
      },
      submitForm (formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.isSubmitLoading = true
            postSupply({
              data: {
                title: this.form.title,
                goodName: this.form.goodName,
                num: this.form.num,
                unitId: this.form.unitId,
                unitName: this.unitList.filter(item => item.id === this.form.unitId)[0].name,
                sku: this.form.sku,
                imageUrl: this.form.imageUrl,
                stopAt: this.form.stopAt,
                receiptAt: this.form.receiptAt,
                price: this.form.price,
                priceStatus: this.form.priceStatus,
                content: this.form.content,
                contactName: this.form.contactName,
                contactPhone: this.form.contactPhone,
              }
            }).then(res => {
              this.isSubmitLoading = false
              if (res.code === 0) {
                this.$message({
                  message: '供应单发布成功',
                  showClose: true,
                  type: 'success'
                })
                this.$router.go(-1)
              }
            }).catch(() => {
              this.isSubmitLoading = false
            })
          } else {
            return false;
          }
        });
      }
    },
    components: {}
  }
</script>
